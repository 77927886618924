var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"intent-table"}},[_c('vs-table',{key:_vm.keyTable,staticClass:"no-opacity",attrs:{"max-items":"5","pagination":"","data":_vm.sortedIntents,"search":_vm.search,"hoverFlat":true,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.name}},[_vm._v(_vm._s(tr.name))]),_vm._v(" "),(!_vm.bot.legacy)?_c('vs-td',{attrs:{"data":tr.examples}},[_c('ul',_vm._l((tr.examples),function(example,index){return _c('li',{key:index},[_vm._v("\n              - "+_vm._s(example)+"\n            ")])}))]):_vm._e(),_vm._v(" "),(!_vm.bot.legacy)?_c('vs-td',{staticClass:"derive-cell"},[_c('vs-checkbox',{staticClass:"mt-3",attrs:{"disabled":_vm.disableDerive || _vm.loading || _vm.readOnly},on:{"change":function($event){_vm.updateIntent(tr)}},model:{value:(tr.derivesToAgent),callback:function ($$v) {_vm.$set(tr, "derivesToAgent", $$v)},expression:"tr.derivesToAgent"}}),_vm._v(" "),(!_vm.disableDerive)?_c('multiselect',{attrs:{"disabled":!tr.derivesToAgent || _vm.loading || _vm.readOnly,"placeholder":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table
                .selectChannels[_vm.languageSelected],"tagPlaceholder":"","show-labels":false,"label":"name","track-by":"value","options":_vm.PLATFORMS,"multiple":true,"taggable":true,"limit":5,"limit-text":_vm.limitText},on:{"input":function($event){_vm.updateIntent(tr)}},model:{value:(tr.intentsByPlatform),callback:function ($$v) {_vm.$set(tr, "intentsByPlatform", $$v)},expression:"tr.intentsByPlatform"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('vs-td',[(!_vm.readOnly)?_c('div',{staticClass:"action-buttons"},[_c('vs-button',{staticClass:"with-space",attrs:{"type":"flat","icon":"create"},on:{"click":function($event){_vm.edit(tr)}}}),_vm._v(" "),_c('vs-button',{attrs:{"type":"flat","icon-pack":"feather","icon":"icon-trash"},on:{"click":function($event){_vm.remove(tr)}}})],1):_vm._e()])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sortKey":"name"}},[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table.name[
            _vm.languageSelected
          ])+"\n      ")]),_vm._v(" "),(!_vm.bot.legacy)?_c('vs-th',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table.examples[
            _vm.languageSelected
          ])+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.bot.legacy)?_c('vs-th',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table
            .derivesToAgent[_vm.languageSelected])+"\n      ")]):_vm._e(),_vm._v(" "),_c('vs-th')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }