<template>
  <add-bot-section :title="title">
    <template slot="actions">
      <vs-button @click="toggleAddIntention" v-if="!readOnly">
        {{ lang.botMaker.botEditor.addIntent.title[languageSelected] }}
      </vs-button>
    </template>
    <template slot="whole-w">
      <div>
        <intent-table
          ref="intentTable"
          :toggle-intent-edit="toggleAddIntention"
          :disable-derive="disableDerive"
          :read-only="readOnly"
        />
        <intents-prompt
          @resetSearch="resetSearch"
          v-if="addIntentPrompt"
          :toggle-prompt="toggleAddIntention"
          :fromCatalog="fromCatalog"
        />
      </div>
    </template>
  </add-bot-section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import IntentTable from './Intent/IntentTable'
import IntentsPrompt from './Intent/IntentsPrompt'
import AddBotSection from './Components/AddBotSection'
import { Intent } from '../../../../models/Intent'
import BotMakerService from '../../../../services/botMaker.service'
import { generateBot } from '../../../../models/BotGenerator'

export default {
  name: 'Intentions',
  props: {
    isEditing: {
      type: Boolean,
      default: true
    },
    newBot: Boolean,
    disableDerive: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    fromCatalog: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IntentTable,
    IntentsPrompt,
    AddBotSection,
  },
  data() {
    return {
      addIntentPrompt: false,
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'newBotLanguage']),
    ...mapGetters('bots', ['bot', 'sectionReady', 'defaultIntentions']),
    ...mapGetters({ botMakerBot: 'botMaker/bot' }),
    title() {
      return !this.hideTitle
        ? this.lang.botMaker.addBot.intentionTriggerSecondLevel.title[
            this.languageSelected
          ]
        : ''
    }
  },
  methods: {
    ...mapMutations('bots', [
      'SET_ADD_INTENT_PROMPT',
      'SET_INTENTION_ID_TO_EDIT',
      'SET_BOT'
    ]),
    ...mapActions('bots', [
      'GET_VERSION_INTENTS',
      'GET_BOTS',
      'GET_ACTIVE_VERSION_BOTS'
    ]),
    toggleAddIntention() {
      this.SET_ADD_INTENT_PROMPT(!this.addIntentPrompt)
      this.addIntentPrompt = !this.addIntentPrompt
    },
    resetSearch() {
      if (this.$refs.intentTable) this.$refs.intentTable.resetSearch()
    },
    getDefaultIntentions() {
      // Only add the default intentions that do not have the same name
      let botIntentions = this.newBot
        ? []
        : this.bot.intents.map(i => (i.name ? i.name.toLowerCase() : ''))
      const languageSelected = this.newBotLanguage

      let defaultIntentionsTmp = this.defaultIntentions.filter(
        defaultIntent => {
          let intent = defaultIntent[languageSelected]
          return !botIntentions.includes(intent.name.toLowerCase())
        }
      )

      this.bot.intents = []
      defaultIntentionsTmp.forEach(defaultIntent => {
        let intentPerLanguage = defaultIntent[languageSelected]
        let intent = new Intent(
          intentPerLanguage.name,
          intentPerLanguage.examples
        )
        intent.derivesToAgent = intentPerLanguage.derivesToAgent
        this.bot.intents.push(intent)
      })

      // default intent response
      this.bot.secondLevelMessage = this.lang.botMaker.addBot.intentionTriggerSecondLevel.secondLevelDerivation.message[
        this.newBotLanguage
      ]
    },
    async getActiveVersionIntentions() {
      if (!this.bot || !this.bot.activeVersion) return
      await this.GET_VERSION_INTENTS(this.bot.activeVersion)
    }
  },
  watch: {
    '$store.state.newBotLanguage'() {
      this.getDefaultIntentions()
    },
    'botMakerBot.activeVersion'() {
      this.getActiveVersionIntentions()
    }
  },
  async mounted() {
    if (!this.bot.id) {
      const botId = this.$route.query.bot
      if (botId) {
        this.$vs.loading()
        try {
          let botResponse = await BotMakerService.getBot(botId)
          this.SET_BOT(generateBot(botResponse.data))
          await this.GET_ACTIVE_VERSION_BOTS([
            this.bot.service.id,
            this.$route.query.version
          ])
        } catch (error) {
          this.$log.error(error)
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected]
          })
        } finally {
          this.$vs.loading.close()
        }
      }
    }

    if (!this.bot.legacy) {
      if (!this.newBot && this.botMakerBot && this.botMakerBot.activeVersion) {
        this.$vs.loading()
        await this.getActiveVersionIntentions()
        this.sectionReady.intentions = true
        this.$vs.loading.close()
      } else if (
        this.newBot ||
        !this.botMakerBot ||
        !this.botMakerBot.hasIntents()
      ) {
        this.getDefaultIntentions()
      }
    } else {
      // When the bot is a legacy one, we need to show the intents such as an array of String
      let intents = this.botMakerBot.intents.map(intent => {
        return new Intent(intent, [], true)
      })
      this.botMakerBot.intents = intents
      this.sectionReady.intentions = true
    }
  }
}
</script>
