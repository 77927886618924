<template>
  <div id="intent-table">
    <vs-table
      :key="keyTable"
      max-items="5"
      pagination
      :data="sortedIntents"
      :search="search"
      :hoverFlat="true"
      :no-data-text="lang.general.noDataText[languageSelected]"
      class="no-opacity"
    >
      <template slot="thead">
        <vs-th sortKey="name">
          {{
            lang.botMaker.addBot.intentionTriggerSecondLevel.table.name[
              languageSelected
            ]
          }}
        </vs-th>
        <vs-th v-if="!bot.legacy">
          {{
            lang.botMaker.addBot.intentionTriggerSecondLevel.table.examples[
              languageSelected
            ]
          }}
        </vs-th>
        <vs-th v-if="!bot.legacy">
          {{
            lang.botMaker.addBot.intentionTriggerSecondLevel.table
              .derivesToAgent[languageSelected]
          }}
        </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">{{ tr.name }}</vs-td>

          <vs-td :data="tr.examples" v-if="!bot.legacy">
            <ul>
              <li v-for="(example, index) in tr.examples" :key="index">
                - {{ example }}
              </li>
            </ul>
          </vs-td>

          <vs-td v-if="!bot.legacy" class="derive-cell">
            <vs-checkbox
                    v-model="tr.derivesToAgent"
                    :disabled="disableDerive || loading || readOnly"
                    class="mt-3"
                    @change="updateIntent(tr)"
            />
            <multiselect
                    v-if="!disableDerive"
                    v-model="tr.intentsByPlatform"
                    :disabled="!tr.derivesToAgent || loading || readOnly"
                    :placeholder="
                lang.botMaker.addBot.intentionTriggerSecondLevel.table
                  .selectChannels[languageSelected]
              "
                    tagPlaceholder=""
                    :show-labels="false"
                    label="name"
                    track-by="value"
                    :options="PLATFORMS"
                    :multiple="true"
                    :taggable="true"
                    :limit="5"
                    :limit-text="limitText"
                    @input="updateIntent(tr)"
            />
          </vs-td>

          <vs-td>
            <div class="action-buttons" v-if="!readOnly">
              <vs-button
                class="with-space"
                type="flat"
                @click="edit(tr)"
                icon="create"
              ></vs-button>
              <vs-button
                type="flat"
                @click="remove(tr)"
                icon-pack="feather"
                icon="icon-trash"
              ></vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PLATFORMS } from '@/constants/constants'

export default {
  name: 'IntentTable',
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  props: {
    toggleIntentEdit: Function,
    disableDerive: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyTable: new Date().toString(),
      search: true,
      PLATFORMS,
      loading: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', [
      'bot',
      'usersByChannelMap'
    ]),
    ...mapGetters({ botMakerBot: 'botMaker/bot' }),
    sortedIntents() {
      return this.sortByKey([...this.bot.intents], 'name')
    }
  },
  methods: {
    ...mapActions('bots', [
      'DELETE_INTENT',
      'UPDATE_INTENT',
      'SET_INTENT_DERIVATE'
    ]),
    ...mapMutations('bots', [
      'SET_INTENTION_ID_TO_EDIT',
      'SET_ADD_INTENT_PROMPT'
    ]),
    limitText(count) {
      return `+${count}`
    },
    sortByKey(array, key) {
      return array.sort(function(a, b) {
        var x = a[key]
        var y = b[key]
        return x < y ? -1 : x > y ? 1 : 0
      })
    },
    resetSearch() {
      this.keyTable = new Date().toString()
      //this.search = true
    },
    edit(intention) {
      this.SET_INTENTION_ID_TO_EDIT(intention.internalId)
      this.toggleIntentEdit()
    },
    async updateBot(intent) {
      await this.SET_INTENT_DERIVATE(intent)
      await this.bot.updateBot(this.session.user.roles, this.usersByChannelMap, this.useStaffManagement)
      return true
    },
    async updateIntent(intent) {
      if(this.loading) return
      this.loading = true
      if (!intent.derivesToAgent) {
        intent.intentsByPlatform = [];
      }
      await this.UPDATE_INTENT(intent)
      await this.updateBot(intent)
      this.loading = false
      this.$notify.success(
              this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
                      .success.title[this.languageSelected],
              this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
                      .successUpdate[this.languageSelected]
      )
    },
    remove(intent) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.botMaker.addBot.intentionTriggerSecondLevel
          .deleteIntentConfirmation.title[this.languageSelected],
        text: this.lang.botMaker.addBot.intentionTriggerSecondLevel
          .deleteIntentConfirmation.content[this.languageSelected],
        acceptText: this.lang.botMaker.accept[this.languageSelected],
        cancelText: this.lang.botMaker.cancel[this.languageSelected],
        accept: () => {
          if (!this.bot.legacy && intent.id) {
            this.$vs.loading()
            const payload = {
              intentId: intent.id,
              versionId: this.botMakerBot.activeVersion || null
            }
            // this.DELETE_INTENT(intent.id)
            this.DELETE_INTENT(payload)
              .then(() => {
                this.$vs.notify({
                  title: this.lang.botMaker.addBot.intentionTriggerSecondLevel
                    .deleteSuccess.title[this.languageSelected],
                  color: 'success'
                })
              })
              .catch(error => {
                this.$log.error(error)
                this.$vs.notify({
                  title: this.lang.errorTitle[this.languageSelected],
                  text: this.lang.error[this.languageSelected],
                  color: 'danger'
                })
              })
              .finally(() => {
                this.$vs.loading.close()
              })
          } else {
            this.bot.intents = this.bot.intents.filter(intentTmp => {
              return intentTmp.internalId !== intent.internalId
            })
          }
        }
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.derive-cell {
  display: -webkit-inline-box;
  span {
    display: inherit;
    .con-vs-checkbox {
      align-items: unset;
    }
    .multiselect {
      min-width: 250px;
      padding-bottom: 30px !important;
      background: transparent;
      width: 100% !important;
      &--disabled {
        .multiselect__select {
          height: 42px;
        }
      }
      .multiselect__content-wrapper{
        max-height: 150px !important;
      }
    }
    .multiselect__tags {
      width: 100% !important;
      max-height: max-content !important;
    }
  }
}
.no-opacity .vs-table--tbody-table .tr-table.hoverFlat:hover {
  opacity: 1 !important;
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
}
.with-space {
  margin-right: 5px;
}
</style>
