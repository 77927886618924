import Vue from 'vue'
import store from '../store/store'

const NOT_AUTHORIZED = [401, 403]
const SERVER_ERROR = 500
const BAD_REQUEST = 400
const BAD_REQUEST_ERRORS = {
  DUPLICATE_INTENT: 'duplicate intent',
  DUPLICATE_EXAMPLE: 'duplicate examples'
}

const _setErrorMessage = (message, error) => {
  let errorData = error && error.response && error.response.data
  let languageSelected = store.state.lang.languageSelected
  let errorsTexts = store.state.lang.errors
  if (
    errorData &&
    typeof errorData === 'object' &&
    errorData.constructor === Array
  ) {
    message.title = errorsTexts.title[languageSelected]
    let errorMessages = errorData.map(e => {
      if (e.message.includes(BAD_REQUEST_ERRORS.DUPLICATE_INTENT)) {
        return errorsTexts.errorDescription.duplicate_intent_name[
          languageSelected
        ]
      }

      if (e.message.includes(BAD_REQUEST_ERRORS.DUPLICATE_EXAMPLE)) {
        return errorsTexts.errorDescription.duplicate_intent_examples[
          languageSelected
        ]
      }
      return errorsTexts.errorDescription[e.message][languageSelected]
    })

    message.text = errorMessages.join()
  }
}

const fieldError = field => {
  const splittedError = field.split('.')
  return splittedError[splittedError.length - 1] || null
}

export const errorHandler = error => {
  // set default error
  let message = {
    text: store.state.lang.errors.description[store.state.languageSelected],
    title: store.state.lang.errors.title[store.state.languageSelected]
  }

  //Setup Error Message
  if (typeof error !== 'undefined') {
    if (error.hasOwnProperty('message') && (error.type || error.field)) {
      const errorType = error.type || fieldError(error.field)

      const customErrorTitle =
        store.state.lang.errors.errorTitle[errorType] &&
        store.state.lang.errors.errorTitle[errorType][
          store.state.languageSelected
        ]

      let customErrorText =
        store.state.lang.errors.errorDescription[errorType] &&
        store.state.lang.errors.errorDescription[errorType][
          store.state.languageSelected
        ]

      let values = error.message.match(/\[(.*?)\]/)
      if (values) {
        values = values[1]
        customErrorText = customErrorText.concat(`: “${values}”`)
      } else if (error.hasOwnProperty('value')) {
        values =
          typeof error.value === 'object' ? error.value.join(',') : error.value
        customErrorText = customErrorText.concat(`: “${values}”`)
      }

      message.title = customErrorTitle || error.message
      message.text = customErrorText || error.message
    } else {
      message.text =
        store.state.lang.errors.errorTitle.e403[store.state.languageSelected] ||
        error.message
    }
  }

  const status = error && error.response && error.response.status

  //Setup status error message
  if (status === BAD_REQUEST) {
    _setErrorMessage((message, error))
  }

  if (NOT_AUTHORIZED.includes(status)) {
    message.title =
      store.state.lang.errors.errorTitle.e403[store.state.languageSelected]
    message.text =
      store.state.lang.errors.errorDescription.e403[
        store.state.languageSelected
      ]
  }

  if (status === SERVER_ERROR) {
    message.title =
      store.state.lang.errors.errorTitle.e500[store.state.languageSelected]
    message.text =
      store.state.lang.errors.errorDescription.e500[
        store.state.languageSelected
      ]
  }

  Vue.prototype.$log.error(error)

  //Toast the Message
  Vue.prototype.$notify.error(message.title, message.text)
}
